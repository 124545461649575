<template>
  <div class="container_box details_box_styles">
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1003204')" prop="wmsPickupOrderNumber">
              <Input :placeholder="$t('key1005488')" disabled clearable v-model.trim="pageParams.wmsPickupOrderNumber"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005486')+ `${configCharacters}` +$t('key1003546')" prop="barCode">
              <Input :placeholder="$t('key1005487')+`${configCharacters}`+$t('key1000827')"
                clearable v-model.trim="pageParams.barCode" @on-enter="scanBarcodeEnter"></Input>
            </Form-item>
          </Col>
          <Button class="ml20" type="primary" @click="completeAdditionBtn" v-if="tableData.length >0 &&
          getPermission('wmsPickupOrder_overAppendGoods')">{{ $t('key1005489') }}</Button>
        </Row>
      </Form>
    </div>
    <!--列表区域-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1005490') }}</h2>
        </template>
        <Table
          border
          :loading="tableLoading"
          max-height="650"
          :columns="tableColumns"
          :data="tableData">
        </Table>
      </Card>
    </div>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from "@/api";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "additionalGoods",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParams: {
        wmsPickupOrderNumber: '',
        barCode: ''
      },
      tableColumns: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000884'),
          key: 'packageCode',
          minWidth: 150,
          align: 'left',
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003198'),
          key: 'warehouseName',
          align: 'left',
          minWidth: 150,
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002771'),
          key: 'trackingNumber',
          minWidth: 150,
          align: 'left'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000787'),
          minWidth: 200,
          align: 'left',
          render: (h, params) => {
            let values = params.row.valueList || [];
            let imgPath = '';
            let skuImgPath = params.row._productInfo.primaryImage;
            let spuImgPath = params.row._productInfo._spuInfo.primaryImage
            imgPath = skuImgPath ? skuImgPath : spuImgPath;
            let name = params.row.name;
            return h('div', {
              class: 'flex mt5 mb5'
            }, [
              v.tableImg(h, params, null, imgPath),
              h('div', {class: 'ml10 flex flex-direction'}, [
                h('p', {class: 'mt5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1000396') + name),
                h('p', {class: 'mt5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1000206') + values.join('、'))
              ])
            ])
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005491'),
          key: 'info',
          align: 'left',
          minWidth: 180,
          render(h, params) {
            return h('div', {
              class: 'flex flex-direction mt5'
            }, [
              h('p', {class: 'mb5'}, 'SPU：' + (params.row.spu ? params.row.spu : '')),
              h('p', {class: 'mb5'}, 'SKU：' + (params.row.sku ? params.row.sku : '')),
              h('p', {class: 'mb5'}, `${setConfigCharacters}SKU：` + (params.row.ymsSku ? params.row.ymsSku : ''))
            ]);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000351'),
          key: 'quantity',
          minWidth: 150,
          align: 'left',
          render(h, params) {
            return h('p', params.row.quantity)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          key: 'options',
          align: 'center',
          width: 100,
          render(h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003055'),
                    show: v.getPermission('wmsPickupOrder_deletePackage'),
                    clickFn: () => {
                      v.tableData.splice(params.index, 1);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      warehouseId: null,
      wmsPickupOrderId: null,
      warehouseList: []
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    if (this.$route.query) {
      this.pageParams.wmsPickupOrderNumber = this.$route.query.pickupOrderNumber;
      this.warehouseId = this.$route.query.warehouseId;
      this.wmsPickupOrderId = this.$route.query.wmsPickupOrderId;
    }
    this.getBelongingWarehouseData(this.userInfo.merchantId).then((data) => {
      if (data && data.length > 0) {
        this.warehouseList = data;
      }
    })
  },
  methods: {
    // 回车扫描YMS条码
    scanBarcodeEnter() {
      let v = this;
      if (v.pageParams.barCode) {
        let query = {
          warehouseId: v.warehouseId,
          wmsPickupOrderId: v.wmsPickupOrderId,
          ymsSku: v.pageParams.barCode
        }
        v.tableLoading = true;
        v.axios.post(api.post_wmsPickupOrder_appendProductGoods, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
          v.tableLoading = false;
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            let newList = [];
            if (data.length > 0) {
              data.map((item) => {
                if (item.wmsPickGoodsBo) {
                  let list = item.wmsPickGoodsBo.goodsDetailBos || [];
                  list.map((ele) => {
                    ele['trackingNumber'] = item.trackingNumber;
                    ele['ymsWarehouseId'] = item.ymsWarehouseId;
                    ele['packageCode'] = item.packageCode;
                  })
                  newList = [...list, ...v.tableData];
                  newList = v.uniqueFunc(newList, 'packageId');
                }
              })
              if (newList.length > 0) {
                v.handerData(newList);
              }
            }
          } else if (response.data.code === 411013) {
            v.$Modal.confirm({
              title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005492'),
              content: alias1bddc4d174174d47a6dfb0437dace856.t('key1005493'),
              okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000138'),
              onOk: () => {
              },
              onCancel: () => {
              }
            });
          }
        });
      } else {
        v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005494'));
        return false;
      }
    },
    // 处理已扫描出库单的数据
    handerData(data) {
      let v = this;
      let newList = [];
      if (data.length > 0) {
        data.map((ele) => {
          ele['path'] = ele.productGoodsImages ? ele.productGoodsImages[0].path : '';
          ele['valueList'] = [];
          if (ele.productGoodsSpecifications && ele.productGoodsSpecifications.length > 0) {
            ele.productGoodsSpecifications.map((talg) => {
              ele['valueList'].push(talg.value);
            });
            ele['valueList'] = v.uniqueFunc(ele['valueList']);
          }
          newList.push(ele);
        });
        if (newList.length > 0) {
          let productGoodsIds = [];
          newList.map((item) => {
            productGoodsIds.push(item.productGoodsId);
          });
          let list = v.uniqueFunc(productGoodsIds);
          if (list.length > 0) {
            (async () => {
              v.tableData = await v.getProductInfoByIds(list, newList,
                false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
            })();
          }
          v.pageParams.barCode = '';
        }
      } else {
        v.tableData = [];
      }
    },
    // 完成追加
    completeAdditionBtn() {
      let v = this;
      let packageIds = [];
      if (v.tableData.length > 0) {
        v.tableData.map((item) => {
          packageIds.push(item.packageId);
        })
      }
      let query = {
        packageIds: packageIds,
        wmsPickupOrderId: v.wmsPickupOrderId
      };
      v.axios.put(api.put_wmsPickupOrder_overAppendGoods, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000310'));
          setTimeout(() => {
            v.$router.push('/shipmentRecord');
          }, 300);
        }
      });
    }
  }
}
</script>

<style scoped>

</style>